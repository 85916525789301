<template>
    <div class="wrapper">
        <router-view />
    </div>
</template>

<style lang="scss">
.wrapper {
    max-width: 40em;
    margin: auto;
}

/* Fonts */
@font-face {
    font-family: $regular-font;
    src: url("~@/assets/fonts/CircularStd-Book.ttf");
}

@font-face {
    font-family: $bold-font;
    src: url("~@/assets/fonts/CircularStd-Bold.ttf");
}

@font-face {
    font-family: $extra-bold-font;
    src: url("~@/assets/fonts/CircularStd-Black.ttf");
}
</style>

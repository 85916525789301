<template>
    <div class="header">
        <progress :value="thisCard.cardId" max="12"></progress>
        <a @click="goHome" class="clickable-area">
            <span class="close"></span>
        </a>
    </div>
    <div class="card-content">
        <div>
            <h2 v-if="thisCard.title">{{ thisCard.title }}</h2>
            <h3 v-if="thisCard.content">{{ thisCard.content }}</h3>
        </div>
        <div v-if="hasAnswers" class="answer-buttons">
            <button
                v-for="answer in thisCard.answers"
                class="secondary"
                :class="{
                    selected: chosenAnswer.label === answer.label,
                }"
                @click="selectAnswer(answer)"
                :key="answer.linkId"
            >
                {{ answer.label }}
            </button>
        </div>
    </div>
    <div class="footer">
        <button class="back-button" @click="previousCard">
            <span class="arrow"></span>
        </button>
        <button class="primary" :disabled="isDisabled" @click="nextCard">
            {{ nextLabel }}
        </button>
    </div>
</template>

<script>
import flowChart from "@/flow-chart.json";

export default {
    data() {
        return {
            chosenAnswer: {},
        };
    },

    props: {
        cardId: {
            type: String,
            required: true,
        },
    },

    computed: {
        hasAnswers() {
            return this.thisCard.answers.length > 0;
        },

        thisCard() {
            return flowChart.find((card) => card.cardId === this.cardId);
        },

        nextLabel() {
            if (!this.hasAnswers) {
                if (this.thisCard.nextCard) {
                    return this.thisCard.nextCard.label;
                } else {
                    return this.thisCard.lastCard.label;
                }
            } else {
                return "Tuir mai";
            }
        },

        isDisabled() {
            return this.hasAnswers && !this.chosenAnswer.label;
        },
    },

    methods: {
        nextCard() {
            const chosen = this.chosenAnswer;
            this.clearSelectedAnswer();

            let nextLinkUrl;

            if (this.thisCard.lastCard) {
                nextLinkUrl = process.env[this.thisCard.lastCard.linkId];
            } else if (
                chosen.isExternalLink ||
                (this.thisCard.nextCard &&
                    this.thisCard.nextCard.isExternalLink)
            ) {
                // next button goes to an external link
                nextLinkUrl = process.env[chosen.linkId];
            } else if (
                this.thisCard.nextCard &&
                !this.thisCard.nextCard.isExternalLink
            ) {
                // continue button goes to the next card
                nextLinkUrl = `${window.location.origin}/#/${this.thisCard.nextCard.linkId}`;
            } else if (!chosen.isExternalLink) {
                // next button goes to the next card
                nextLinkUrl = `${window.location.origin}/#/${chosen.linkId}`;
            } else {
                throw new Error("Missing link to page");
            }
            window.location.href = nextLinkUrl;
        },

        previousCard() {
            this.clearSelectedAnswer();
            this.$router.go(-1);
        },

        goHome() {
            window.location.href = process.env["VUE_APP_REFERE"];
        },

        selectAnswer(answer) {
            this.chosenAnswer = answer;
        },

        clearSelectedAnswer() {
            this.chosenAnswer = {};
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    height: 50px;
    display: flex;
    align-items: center;

    progress {
        margin-left: 15px;
    }

    .close {
        @include mask-icons-shared(
            "~@/assets/images/x_close.svg",
            contain,
            $dark-gray
        );
        height: 15px;
        width: 15px;
        margin: 15px;
    }

    .clickable-area {
        width: 50px;
        height: 45px;
    }
}

.card-content {
    border-radius: 8px;
    background-color: $not-quite-white;
    box-shadow: 0 10px 20px 0 rgba(42, 57, 66, 0.2);
    height: calc(90vh - 50px);
    margin: 0 5px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-y: scroll;

    h2 {
        margin-bottom: 1em;
    }

    .answer-buttons {
        margin-bottom: 1em;
    }
}

.footer {
    position: fixed;
    bottom: 15px;
    width: 100%;
    display: flex;
    max-width: inherit;
    justify-content: space-around;

    button.primary {
        max-width: 140px;
    }

    button.back-button {
        background-color: white;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        box-shadow: 0 5px 8px 0 rgba(42, 57, 66, 0.1);
        display: flex;
        align-items: center;
    }

    .arrow {
        @include mask-icons-shared(
            "~@/assets/images/arrow.svg",
            contain,
            $app-accent-color
        );
        height: 13px;
        width: 100%;
        margin-left: 2px;
    }
}
</style>

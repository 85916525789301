import { createRouter, createWebHashHistory } from "vue-router";
import CardFrame from "@/views/CardFrame.vue";

const routes = [
    { path: "/", redirect: "/1" },
    {
        path: "/:cardId",
        name: "Card",
        component: CardFrame,
        props: true,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;

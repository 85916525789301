import { render } from "./CardFrame.vue?vue&type=template&id=744bee1a&scoped=true"
import script from "./CardFrame.vue?vue&type=script&lang=js"
export * from "./CardFrame.vue?vue&type=script&lang=js"

import "./CardFrame.vue?vue&type=style&index=0&id=744bee1a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-744bee1a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "744bee1a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('744bee1a', script)) {
    api.reload('744bee1a', script)
  }
  
  module.hot.accept("./CardFrame.vue?vue&type=template&id=744bee1a&scoped=true", () => {
    api.rerender('744bee1a', render)
  })

}

script.__file = "src/views/CardFrame.vue"

export default script